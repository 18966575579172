import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import theme from "../../theme/theme";
import { type } from "../../theme/typography";
import Color from "color";

import Layout from "../../components/layout";
import Container from "../../components/container";
import SEO from "../../components/seo";

const colors = {
  backgroundColor: "hsl(200, 20%, 98%)",
  bodyColor: "hsl(231, 38%, 22%)",
  footerColor: "hsl(246, 8%, 48%)"
};

const ArticleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.spacing.small};
  padding-top: ${theme.spacing.small};
  ${theme.breakpoint.down("small")`
    padding-top: ${theme.spacing.xxsmall};
  `}
`;

const Article = styled.div`
  flex: 0 1 50%;
  padding: ${theme.spacing.small};
  &:first-child {
    flex: 1 1 100%;
  }
  ${theme.breakpoint.down("medium")`
    flex: 1 1 100%;
  `};
  h2 {
    ${type("header2")};
    color: ${colors.bodyColor};
    text-transform: capitalize;
    margin: ${theme.spacing.small} 0 ${theme.spacing.xxsmall} 0;
    max-width: ${theme.variables.siteMeasure}px;
  }
  p {
    margin: ${theme.spacing.xsmall} 0 0 0;
    ${type("bodySmall")};
    color: ${Color(colors.bodyColor)
      .fade(0.2)
      .string()};
    max-width: ${theme.variables.siteMeasure}px;
  }
`;

const Thumbnail = styled(Img)`
  min-height: 200px;
  max-height: 260px;
`;

const ArticleTitle = styled.strong`
  color: ${colors.bodyColor};
  ${type('body')};
  font-weight: 500;
  line-height: 27px;
`

export default ({ data }) => (
  <React.Fragment>
    <SEO title="Writing" description="Recent writings on product development, design, art, technology, and business."/>
    <Layout
      backgroundColor={colors.backgroundColor}
      bodyColor={colors.bodyColor}
      footerColor={colors.footerColor}
      navColor={colors.bodyColor}
    >
      <Container>
        <ArticleWrapper>
          {data.allMarkdownRemark.edges
            .filter(({ node }) => node.frontmatter.template === "writing")
            .map(({ node }, index) => (
              <Article key={node.id}>
            
                <Link to={node.fields.slug}>
                  <Thumbnail
                    fluid={node.frontmatter.headerImage.childImageSharp.fluid}
                    alt={"Thumbnail image for " + node.frontmatter.title}
                  />
                  <p>
                  <ArticleTitle>{node.frontmatter.title}</ArticleTitle> {" / "}
                  {node.frontmatter.abstractShort}
                  </p>
                </Link>
              </Article>
            ))}
        </ArticleWrapper>
      </Container>
    </Layout>
  </React.Fragment>
);

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            template
            abstractShort
            abstractLong
            headerImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
